import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import DetailsContainer from '../container/details-container';
import DetailsItem from '../item/details-item';
import { IDetailsItem } from '../item/details-item-interface';
import { ICompetitionDetailsProperties } from './competition-details-interface';

export default class CompetitionDetails extends BaseComponent<ICompetitionDetailsProperties> {
  protected declareTranslateCollection(): string {
    return 'competitionDetails';
  }

  public render(): ReactNode {
    const { overall, items } = this.props;

    return (
      <DetailsContainer>
        <h3 className="c-score__title">{this.translate('overallTitle')}</h3>
        <DetailsItem item={overall} />
        <h3 className="c-score__title">{this.translate('componentTitle')}</h3>
        {items.map((item: IDetailsItem, index: number): ReactNode => {
          return <DetailsItem key={index} item={item} />;
        })}
      </DetailsContainer>
    );
  }
}

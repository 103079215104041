import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import { ICyclePercentChartPoint } from '../../components/charts/cycle-percent/cycle-percent-interface';
import { ICycleScoreChartPoint } from '../../components/charts/cycle-score/cycle-score-interface';
import ContainerBox from '../../components/container-box/container-box';
import CriteriaGrade from '../../components/criteria-grade/criteria-grade';
import CompetitionDetails from '../../components/details/competition/competition-details';
import ImproveBox from '../../components/improve-box/improve-box';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import StateLayout from '../../components/state-layout/state-layout';
import { ISimilarStatesItem } from '../../components/states/similar-states/item/item-interface';
import SimilarStates from '../../components/states/similar-states/similar-states';
import {
  SODComponents,
  SODCycles,
  SODGrade,
  SODMeasures,
} from '../../global/global-enum';
import { ISODNode } from '../../global/global-interfaces';
import { IStateCompetitionTemplateProperties } from './state-competition-interface';

export default class StateCompetitionTemplate extends BaseComponent<IStateCompetitionTemplateProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateCompetitionTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const {
      competitionMarginOfVictory,
      competitionTotal,
      competitionUnCompetitive,
      competitionUnContested,
      competitionWinnersShare,
      lesserScoreSimilarStates,
      greaterScoreSimilarStates,
      icon,
    } = data;

    const { breadCrumbs, pageContent } = pageContext;
    const {
      competitionScoreDesc1,
      competitionScoreDesc2,
      improveCompetitionDesc,
      improveCompetitionSolution1,
      improveCompetitionSolution2,
      improveCompetitionSolution3,
      hvdScoreDesc,
      winnersShareDesc,
      marginVictoryDesc,
      unContestedDesc,
      unCompetitiveDesc,
    } = pageContent;
    const competitionAverage = this.populateAverageData(SODComponents.TOTAL);
    const marginAverage = this.populateAverageData(
      SODComponents.COMPETITION_MARGIN_OF_VICTORY
    );
    const unCompetitiveAverage = this.populateAverageData(
      SODComponents.COMPETITION_UNCOMPETITIVE_SEATS
    );
    const unContestedAverage = this.populateAverageData(
      SODComponents.COMPETITION_UNCONTESTED_RACES
    );
    const winnersAverage = this.populateAverageData(
      SODComponents.COMPETITION_WINNERS_SHARE
    );

    const competitionOverall = this.populateOverallData(SODComponents.TOTAL);
    const marginOverall = this.populateOverallData(
      SODComponents.COMPETITION_MARGIN_OF_VICTORY
    );
    const unCompetitiveOverall = this.populateOverallData(
      SODComponents.COMPETITION_UNCOMPETITIVE_SEATS
    );
    const unContestedOverall = this.populateOverallData(
      SODComponents.COMPETITION_UNCONTESTED_RACES
    );
    const winnersOverall = this.populateOverallData(
      SODComponents.COMPETITION_WINNERS_SHARE
    );

    const totalCycleScores: ICycleScoreChartPoint[] =
      competitionTotal.nodes.map((node) => {
        const { cycle, score, grade, weight } = node;
        return {
          cycle,
          score: Math.round(score * 100),
          grade,
          weight,
        };
      });

    const hvdCyclePercents: ICyclePercentChartPoint[] =
      competitionTotal.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.value * 100),
        };
      });

    const winnersCyclePercents: ICyclePercentChartPoint[] =
      competitionWinnersShare.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.value * 100),
        };
      });

    const marginCyclePercents: ICyclePercentChartPoint[] =
      competitionMarginOfVictory.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.value * 100),
        };
      });

    const unContestedCyclePercents: ICyclePercentChartPoint[] =
      competitionUnContested.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.value * 100),
        };
      });

    const unCompetitiveCyclePercents: ICyclePercentChartPoint[] =
      competitionUnCompetitive.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.value * 100),
        };
      });

    const lesserScoreSimilarStatesItems = lesserScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/competition`,
        };
      }
    );

    const greaterScoreSimilarStatesItems = greaterScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/competition`,
        };
      }
    );

    const stateUrl: string = generateUrlForStates(competitionOverall.state);
    const { publicURL: iconUrl = '' } = icon.nodes[0];

    return (
      <StateLayout
        title={`${this.translate('title')}(${competitionOverall.state})`}
        description={this.translate('firstScoreDescription')}
        state={competitionOverall.state}
        image={iconUrl}
        breadCrumbItems={breadCrumbs}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${competitionOverall.state} ${this.translate('title')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-competition-score-${generateUrlForStates(
            competitionOverall.state
          )}.png`}
        />
        <ContainerBox
          className="c-section--score is-criteria-grade"
          id="competition"
        >
          <div className="c-score">
            <CriteriaGrade
              title={this.translate('scoreTitle')}
              averageScore={Math.round(competitionAverage.score * 100)}
              averagePercent={Math.round(competitionAverage.value * 100)}
              overallPercentage={Math.round(competitionOverall.value * 100)}
              cycleScores={totalCycleScores}
              overallGrade={competitionOverall.grade}
              overallScore={Math.round(competitionOverall.score * 100)}
              descriptions={[competitionScoreDesc1, competitionScoreDesc2]}
            />
            <Share
              absoluteLink={`/state/${stateUrl}/competition`}
              embeddedLink={`/embedded/competition/${stateUrl}`}
              imageResourceFileName={`embedded-competition-score-${generateUrlForStates(
                competitionOverall.state
              )}.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--similar">
          <SimilarStates
            lesserScoreStates={lesserScoreSimilarStatesItems}
            greaterScoreStates={greaterScoreSimilarStatesItems}
            activeState={{
              abbreviation: competitionOverall.code,
              grade: competitionOverall.grade,
              score: Math.round(competitionOverall.score * 100),
              title: competitionOverall.state,
              itemLink: `/state/${generateUrlForStates(
                competitionOverall.state
              )}/competition`,
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--improve">
          <ImproveBox
            improveBoxItems={{
              title: this.translate('improveCompetition'),
              description: improveCompetitionDesc,
              items: [
                improveCompetitionSolution1,
                improveCompetitionSolution2,
                improveCompetitionSolution3,
              ],
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--details">
          <CompetitionDetails
            overall={{
              title: this.translate('hvdScore'),
              description: hvdScoreDesc,
              value: Math.round(competitionOverall.value * 100),
              averageValue: Math.round(competitionAverage.value * 100),
              chartData: hvdCyclePercents,
            }}
            items={[
              {
                title: this.translate('winnersShare'),
                description: winnersShareDesc,
                value: Math.round(winnersOverall.value * 100),
                averageValue: Math.round(winnersAverage.value * 100),
                chartData: winnersCyclePercents,
              },
              {
                title: this.translate('marginVictory'),
                description: marginVictoryDesc,
                value: Math.round(marginOverall.value * 100),
                averageValue: Math.round(marginAverage.value * 100),
                chartData: marginCyclePercents,
              },
              {
                title: this.translate('unContested'),
                description: unContestedDesc,
                value: Math.round(unContestedOverall.value * 100),
                averageValue: Math.round(unContestedAverage.value * 100),
                chartData: unContestedCyclePercents,
              },
              {
                title: this.translate('unCompetitive'),
                description: unCompetitiveDesc,
                value: Math.round(unCompetitiveOverall.value * 100),
                averageValue: Math.round(unCompetitiveAverage.value * 100),
                chartData: unCompetitiveCyclePercents,
              },
            ]}
          />
        </ContainerBox>
      </StateLayout>
    );
  }

  private populateOverallData(component: SODComponents): ISODNode {
    const { overall } = this.props.data;
    const componentOverall: ISODNode = overall.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentOverall;
  }

  private populateAverageData(component: SODComponents): ISODNode {
    const { average } = this.props.data;
    const componentAverage: ISODNode = average.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentAverage;
  }
}

export const query = graphql`
  query StateCompetition($state: String, $score: Float) {
    competitionTotal: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }

    competitionWinnersShare: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Winner's Share" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }

    competitionMarginOfVictory: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Margin of Victory" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }
    competitionUnContested: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Uncontested Races" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }
    competitionUnCompetitive: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Uncompetetive Seats" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }
    lesserScoreSimilarStates: allSheetDataCompetition(
      sort: { fields: score, order: DESC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { lte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }

    greaterScoreSimilarStates: allSheetDataCompetition(
      sort: { fields: score, order: ASC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { gte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }

    average: allSheetDataCompetition(
      filter: { state: { eq: "US Average" }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    overall: allSheetDataCompetition(
      filter: { state: { eq: $state }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    icon: allFile(
      filter: {
        name: { eq: $state }
        ext: { eq: ".svg" }
        relativeDirectory: { eq: "images/states" }
      }
    ) {
      nodes {
        name
        publicURL
        relativeDirectory
      }
    }
  }
`;
